import React from 'react';
// @ts-ignore
import { createRoot } from 'react-dom/client';

type Config = {
  root?: string;
  rootPattern?: string;
};

const getRootEls = (config: Config) => {
  if (config && config.root) {
    return [document.getElementById(config.root)];
  }
  if (config && config.rootPattern) {
    return Array.from(
      document.querySelectorAll(`[id*="${config.rootPattern}"]`),
    );
  }
  return [document.getElementById('root')];
};

const renderApp = (
  component: React.FC | React.ComponentClass,
  rootEl: Element,
) => {
  if (!rootEl) return;
  const props = JSON.parse(rootEl.getAttribute('data-react-props'));
  const root = createRoot(rootEl);
  root.render(React.createElement(component, props));
};

const initApp = (
  component: React.FC | React.ComponentClass,
  config?: Config,
) => {
  const rootEls = getRootEls(config).filter((n) => n); // filter out nulls
  if (rootEls.length === 0) return;
  rootEls.forEach((rootEl) => renderApp(component, rootEl));
};

export default initApp;
